<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Tipo de documento*"
            label-for="document_type"
          >
            <v-select
              v-model="formData.document_type_id"
              label="text"
              placeholder="Seleccione tipo de documento"
              :options="documentTypes"
              :reduce="(option) => option.value"
            >
              <div slot="no-options">
                No hay opciones
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Número de identificación*"
            label-for="document_number"
          >
            <b-form-input
              id="document_number"
              v-model="formData.document_number"
              placeholder="Número de documento"
              @blur="$v.formData.document_number.$touch()"
            />
            <span
              v-if="
                $v.formData.document_number.$error &&
                  !$v.formData.document_number.numeric
              "
              class="help-block text-danger"
            >Ingrese solo números.</span>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Nombre*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.first_name"
              placeholder="Nombre"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Apellido*"
            label-for="last_name"
          >
            <b-form-input
              id="last_name"
              v-model="formData.last_name"
              placeholder="Apellido"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Género*"
            label-for="gender_id"
          >
            <v-select
              v-model="formData.gender_id"
              label="text"
              placeholder="Seleccione género"
              :options="genders"
              :reduce="(option) => option.value"
            >
              <div slot="no-options">
                No hay opciones
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <date-picker
            v-model="formData.birth_date"
            :attributes="attrs"
            mode="date"
            :model-config="modelConfig"
            :available-dates="{ start: null, end: new Date() }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <b-form-group
                label="Fecha de nacimiento*"
                label-for="birth_date"
              >
                <b-form-input
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </b-form-group>
            </template>
          </date-picker>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="EPS*"
            label-for="hpc_id"
          >
            <v-select
              v-model="formData.hpc_id"
              label="text"
              placeholder="Seleccione EPS"
              :options="hpcs"
              :reduce="(option) => option.value"
            >
              <div slot="no-options">
                No hay opciones
              </div>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          &nbsp;
        </b-col>

        <b-col>
          <b-row>
            <b-col md="6" />
            <b-col
              md="6"
              class="text-right"
            >
              <b-button
                type="button"
                variant="primary"
                :disabled="isBusy || $v.$invalid"
                @click="saveData"
              >
                <b-spinner
                  v-if="isBusy"
                  small
                />
                <v-icon
                  v-else
                  name="save"
                />
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
	BForm,
	BFormGroup,
	BButton,
	BCol,
	BFormInput,
	BRow,
	BSpinner,
} from 'bootstrap-vue'
import { required, numeric } from 'vuelidate/lib/validators'
import vSelect from 'vue-select'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import CRUDMixin from '@/mixins/CRUDMixin'

export default {
	components: {
		BForm,
		BFormGroup,
		BButton,
		BCol,
		BFormInput,
		BRow,
		BSpinner,
		vSelect,
		DatePicker,
	},
	mixins: [CRUDMixin],
	props: {
		record: {
			type: Object,
			default: null,
		},
	},
	inject: ['patientsRepository', 'datafieldsRepository'],

	data() {
		return {
			formData: {
				first_name: '',
				last_name: '',
				document_number: '',
				birth_date: '',
				document_type_id: '',
				gender_id: '',
				hpc_id: '',
			},
			repository: 'patientsRepository',
			isBusy: false,
			documentTypes: [],
			genders: [],
			hpcs: [],
			modelConfig: {
				type: 'string',
				mask: 'YYYY-MM-DD',
			},
			attrs: [
				{
					highlight: 'green',
					dates: new Date(),
				},
			],
		}
	},

	async mounted() {
		const me = this
		if (me.record) {
			me.formData = { ...me.record }
		}

		const documentTypes = await me.datafieldsRepository.getAll('document_type')
		me.documentTypes = documentTypes.data.map(item => ({
			value: item.id,
			text: item.longName,
		}))

		const genders = await me.datafieldsRepository.getAll('gender')
		me.genders = genders.data.map(item => ({
			value: item.id,
			text: item.longName,
		}))

		const hpcs = await me.datafieldsRepository.getAll('hpc')
		me.hpcs = hpcs.data.map(item => ({
			value: item.id,
			text: item.longName,
		}))
	},

	validations: {
		formData: {
			first_name: {
				required,
			},
			last_name: {
				required,
			},
			document_number: {
				required,
				numeric,
			},
			birth_date: {
				required,
			},
			document_type_id: {
				required,
			},
			hpc_id: {
				required,
			},
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
